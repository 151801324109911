module.exports = {
    init: function () {
        truncate_event_item_description();
    }
}

function truncate_event_item_description() {

    $('.event-item').each(function() {
        $(this).children('p').trunk8({
            lines: 1,
            fill: '&hellip; <a id="read-more" class="toggle-truncate" href="#">mehr</a>',
            onTruncate: function() {

                // set "more" click action
                $('.event-item').find('#read-more').each(function() {

                    $(this).unbind('click');
                    $(this).click(function() {

                        // expand the truncated text and add "less" link
                        $(this).parent().trunk8('revert').append(' <a id="read-less" class="toggle-truncate" href="javascript:void(0)">weniger</a>');

                        // set "less" click action
                        $('.event-item').find('#read-less').each(function () {
                            $(this).unbind('click');
                            $(this).click(function () {

                                // truncate again
                                $(this).parent().trunk8();

                                // set side bar height
                                Layout.set_sidebar_height();

                                return false;
                            });
                        });

                        // set side bar height
                        Layout.set_sidebar_height();

                        return false;
                    });
                });
            }
        });
    });
}