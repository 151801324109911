module.exports = {

    header_initial_height: 0,
    header_small_height: 63,
    navbar_minified: false,
    header_minify_offset: 100,
    initialized: false,

    init: function() {

        Layout.initialized = true;

        update_wrapper_offset($(window).scrollTop() == 0);
        truncate_sidebar_content_description();
        Layout.set_sidebar_height();
        window.setTimeout(function() {
            $('html').addClass('transitions-enabled');
        },300);

        $(window).on('scroll', function () {
            update_header_size();
            // wait for the header css transition to finish
            window.setTimeout(function() {
                update_wrapper_offset();
            },300);
        });

        $(window).on('resize', function () {
            $('.navbar').height('auto');
            update_wrapper_offset();
            update_header_size();
            Layout.set_sidebar_height();
        });

        $('#close-sidebar').click(function() {
            Layout.toggle_sidebar();
        });

        // if sidebar is minified by default
        var sidebar = $('.sds-sidebar');
        if(sidebar.hasClass('minified')) {
            sidebar.click(function() {
                Layout.toggle_sidebar();
            });
        } else {
            // minify sidebar on smart screens by default
            if(ResponsiveBootstrapToolkit.is('<=xs')) {
                Layout.toggle_sidebar();m
            }
        }
    },

    toggle_sidebar: function (forceClose, callback) {
        var sidebar = $('.sds-sidebar');

        if(forceClose) {
            if(!sidebar.hasClass('minified')) {
                sidebar.addClass('minified');
                sidebar.one('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd', callback);
                sidebar.click(function() {
                    Layout.toggle_sidebar();
                });
            }
        } else {
            if(sidebar.hasClass('minified')) {
                sidebar.removeClass('minified');
                sidebar.unbind('click');
            } else {
                sidebar.addClass('minified');
                window.setTimeout(function() {
                    sidebar.click(function() {
                        Layout.toggle_sidebar();
                    });
                },100);
            }
        }

    },

    bind_end_transition: function(element, callback) {
        if(typeof element != 'object') {
            element = $('#'+element);
        }
        element.bind('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd', function(){
            callback();
        });
    },

    set_sidebar_height: function() {

        // for sm up screens
        if(ResponsiveBootstrapToolkit.is('>xs')) {

            var sidebar_needed_height = get_sidebar_needed_height();

            // if sidebar height is needed "unfix" the sidebar
            // and if main controller height is adjustable (not for iframe embedding)
            if(sidebar_needed_height > 0 && $('#main-container').find('.row.no-height-adjustment').length == 0) {
                // subtract the footer height (which was added initially as bottom margin to the list inside the sidebar)
                // sidebar_needed_height = sidebar_needed_height - footer_height;
                var new_main_container_height = 0;
                var main_content_height = $('#content > *').height() + parseInt($('#content').css('margin-top'))  + parseInt($('#content').css('margin-bottom'));
                // if the main container is smaller than the new sidebar height
                if(main_content_height < sidebar_needed_height - 1) {
                    // grow the main container to sidebar height
                    new_main_container_height = sidebar_needed_height - 1
                } else {
                    // grow/shrink the sidebar container to main content height
                    new_main_container_height = main_content_height;
                    sidebar_needed_height = main_content_height + 2;
                }
                $('#main-container').height(new_main_container_height);
                // unfix the sidebar and set the new sidebar height
                unfix_sidebar(true, sidebar_needed_height);
            } else {
                $('#main-container').height('auto');
                unfix_sidebar(false);
            }
        }

    }
}

function unfix_sidebar(unfix, sidebar_height) {
    var sidebar = $('.sds-sidebar');
    // unfix
    if(unfix) {
        // add not-fixed class (position absolute)
        sidebar.addClass('not-fixed');
        // set the new sidebar height
        sidebar.outerHeight(sidebar_height);
    } else {
        // remove not-fixed class (position fixed again)
        sidebar.removeClass('not-fixed');
        // reset height to intial value
        sidebar.css('height','100vh');
    }
    update_sidebar_top_offset();
}

function update_wrapper_offset(set_initial_height) {
    if(set_initial_height) {
        window.setTimeout(function() {
            if(!Layout.header_minified) {
                Layout.header_initial_height = $('.navbar').height();
            }
            update_header_size();
            set_wrapper_padding();
        },100);
    } else {
        set_wrapper_padding();
    }
}

function set_wrapper_padding() {
    // set wrapper padding only if the old value is smaller than the assigned
    if(parseInt($('#wrapper').css('padding-top')) < $('.navbar').height() + 1) {
        $('#wrapper').css('padding-top', $('.navbar').height() + 1);
    }
}

function update_header_size() {
    var min_sm = ResponsiveBootstrapToolkit.is('>xs');
    if($(window).scrollTop() > Layout.header_minify_offset) {
        Layout.header_minified = true;
        $('.hide-for-small-header').hide();
        $('.navbar').css('min-height',Layout.header_small_height);
        $('.navbar').height(Layout.header_small_height);
        $('#logo').addClass('small');
        $('.navbar-toggle').addClass('small');
    } else {
        Layout.header_minified = false;
        $('.hide-for-small-header').show();
        $('#logo').removeClass('small');
        $('.navbar-toggle').removeClass('small');
        $('.sds-sidebar').children('div:first').css('margin-top', 0);

        if(Layout.header_initial_height == 0) {
            $('.navbar').height('auto');
            window.setTimeout(function() {
                Layout.header_initial_height = $('.navbar').height();
                $('.navbar').css('min-height',Layout.header_initial_height);
                $('.navbar').height(Layout.header_initial_height);
            }, 300);
        } else {
            $('.navbar').css('min-height',Layout.header_initial_height);
            $('.navbar').height(Layout.header_initial_height);
        }
    }
    update_sidebar_top_offset();
}

function update_sidebar_top_offset() {
    if (ResponsiveBootstrapToolkit.is('<=xs')) {
        // remove top offsets on xs
        $('.sds-sidebar').css('margin-top', 0).css('padding-top', 0);
    } else {

        // get sidebar top offset (only applied to fixed sidebar)
        var sidebar_top_offset = Layout.header_initial_height;

        if ($(window).scrollTop() > Layout.header_minify_offset) {
            sidebar_top_offset = Layout.header_small_height;
        }

        // if header is not fixed (absolute) apply margin
        if ($('.sds-sidebar').hasClass('not-fixed')) {
            $('.sds-sidebar').css('margin-top', Layout.header_initial_height).css('padding-top', 0);
        // if header is fixed apply padding
        } else {
            $('.sds-sidebar').css('margin-top', 0).css('padding-top', sidebar_top_offset);
        }
    }
}

function get_sidebar_needed_height() {

    // get the available sidebar height
    var available_sidebar_viewport = $(window).height() - $('.navbar').height() - $('footer').height();

    // get the needed sidebar height
    var sidebar_heading_height = $('.sds-sidebar div:first-child').outerHeight();
    var sidebar_content_height = $('#sidebar_content').height() + parseInt($('.sds-sidebar div:first-child').css('padding-top')) + parseInt($('.sds-sidebar div:first-child').css('padding-bottom'));
    var needed_sidebar_height = sidebar_heading_height + sidebar_content_height;

    // if the need height is greater than the available viewport return the needed height
    return (needed_sidebar_height > available_sidebar_viewport) ? needed_sidebar_height : 0;

    // // if the content of the sidebar is larger than the sidebar container itself return the content height
    // return sidebar_height - sidebar_heading_height - sidebar_content_height < 0 ? sidebar_heading_height + sidebar_content_height : 0;
}

function truncate_sidebar_content_description() {
    $('.sidebar-event-description').trunk8({
        lines: 1,
        fill: '&hellip; <a id="read-more" class="toggle-truncate" href="javascript:void(0)">mehr</a>',
        onTruncate: function() {

            // set "more" click action
            $('.sidebar-event-description').find('#read-more').each(function() {

                $(this).unbind('click');
                $(this).click(function() {

                    // expand the truncated text and add "less" link
                    $(this).parent().trunk8('revert').append(' <a id="read-less" class="toggle-truncate" href="javascript:void(0)">weniger</a>');

                    // set "less" click action
                    $('.sidebar-event-description').find('#read-less').each(function () {
                        $(this).unbind('click');
                        $(this).click(function () {

                            // truncate again
                            $(this).parent().trunk8();

                            // set side bar height
                            Layout.set_sidebar_height();

                            return false;
                        });
                    });

                    // set side bar height
                    Layout.set_sidebar_height();

                    return false;
                });
            });
        }
    });
}