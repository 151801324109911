module.exports = {

    container: $('<div class="panel-group accordion" role="tablist" aria-multiselectable="true"></div>'),
    inner_container: $('<div class="panel panel-default"></div>'),
    panel: $('<div class="panel panel-default"></div>'),

    init: function() {
        update_accordion_class(true);

        // update accordion with expanded class
        $('.accordion').on('show.bs.collapse', function (e) {
            update_accordion_class(false, e.target, true);
        });

        $('.accordion').on('hide.bs.collapse', function (e) {
            update_accordion_class(false, e.target);
        });

        // update accordion with expanded class
        $('.accordion').on('shown.bs.collapse', function (e) {
            Layout.set_sidebar_height();
        });
    },

    parse_accordions: function() {
        var headings = $('#content').find('h4.accordion');

        if(headings.length > 0) {

            var previous = [];
            var container = [];
            var index = -1;
            var element_index = -1;

            // loop through accordion headings
            headings.each(function (i) {

                element_index++;

                // if the previous element is not accordion
                if(!$(this).prev().hasClass('accordion')) {
                    index++;
                    element_index = 0
                    container[index] = Accordion.container.clone();
                    container[index].attr('id','accordion-'+index);
                    previous[index] = $(this).prev();
                }

                // clone the new headings and panel
                var heading = $(this).clone();
                var panel = $(this).next('div.accordion').clone();

                // hide original markup
                $(this).addClass('hidden');
                $(this).next('div.accordion').addClass('hidden');

                // add inner container
                var inner_container = Accordion.inner_container.clone();
                inner_container.append(get_heading(index, element_index, heading));
                inner_container.append(get_panel(index, element_index, panel));

                // append to main accordion container
                container[index].append(inner_container);
            });

            $(previous).each(function(i) {
                $(this).after(container[i]);
            });
        }
    }
}

function update_accordion_class(init, target, show) {
    // identify by collapse(.in) class
    if(init) {
        set_class($('.panel-collapse.collapse:not(.in)'));
        set_class($('.panel-collapse.collapse.in'),true);
        window.setTimeout(function() {
            $('.panel-heading').addClass('animated');
        },300);
    } else {
        // show event
        if(show) {
            // add expanded class to target
            set_class($(target),true);
        } else {
            // remove expanded class from target
            set_class($(target));
        }
    }
}

function set_class(obj, add) {
    if(add) {
        obj.closest('.panel').addClass('expanded');
    } else {
        obj.closest('.panel').removeClass('expanded');
    }
}

function get_heading(index, element_index, heading) {

    var aria_expanded = element_index == 0 ? 'true' : 'false';
    var heading_container = $('<div class="panel-heading" role="tab" id="heading-'+index+'-'+element_index+'"></div>');
    var heading_anchor = '<a role="button" data-toggle="collapse" data-parent="#accordion-'+index+'" href="#collapse-'+index+'-'+element_index+'" aria-expanded="'+aria_expanded+'" aria-controls="collapse-'+index+'-'+element_index+'"></a>';
    var heading_inner = heading.removeClass('accordion').addClass('panel-title');
    var heading = heading_container.append(heading_inner.wrapInner(heading_anchor));

    return heading;
}

function get_panel(index, element_index, panel) {

    var collapsed_in = element_index == 0 ? 'in' : '';
    var panel_container = $('<div id="collapse-'+index+'-'+element_index+'" class="panel-collapse collapse '+collapsed_in+'" role="tabpanel" aria-labelledby="heading-'+index+'-'+element_index+'"></div>');
    var panel_inner = panel.removeClass('accordion').addClass('panel-body');

    return panel_container.append(panel);
}