try {

    window.$ = window.jQuery = require('jquery');

    require('bootstrap-sass');

    window.$.trunk8 = require('trunk8');

    window.$.dotdotdot = require('dotdotdot');

    window.ResponsiveBootstrapToolkit = require('responsive-toolkit');

    window.Layout = require('./layout');

    window.Accordion = require('./accordion');

    window.News = require('./news');

    window.Events = require('./events');

    window.Iframe = require('./iframe');

} catch (e) {}