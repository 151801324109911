module.exports = {
    init: function() {

        set_click_action();
        News.truncate_item_description();

        $(window).on('resize', function () {
            set_click_action();
            News.truncate_item_description();
        });
    },

    truncate_item_description: function () {
        if(ResponsiveBootstrapToolkit.is('>sm')) {
            $('.news-item.small').find('.text').each(function() {
                var text_outer_container_height = $(this).height();
                var inner_container_top_padding = parseInt($(this).children('div').css('padding-top'));
                var date_height = $(this).find('.date').height();
                var title_height = $(this).find('h2').height();
                var title_margin = parseInt($(this).find('h2').css('margin-top')) + parseInt($(this).find('h2').css('margin-bottom'));
                var action_link_height = $(this).find('.action-link').height();
                var action_link_bottom = parseInt($(this).find('.action-link').css('bottom'));
                var truncated_description_height = text_outer_container_height - inner_container_top_padding - date_height - title_height - title_margin - action_link_height - action_link_bottom - 5;

                $(this).find('.description').dotdotdot({
                    height: truncated_description_height
                });
            });
        }
    }
}

function set_click_action() {
    $('.news-item.small').each(function() {
        if(ResponsiveBootstrapToolkit.is('>sm'))  {
            $(this).click(function() {
                top.location.href = $(this).find('.action-link a').attr('href');
            });
        } else {
            $(this).unbind('click');
        }
    });
}